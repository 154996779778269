<script>
import routeType from '@/router/routeType'
import AuthMixin from '@/components/mixins/AuthMixin'
export default {
    mixins: [AuthMixin],

    mounted() {
        if (this.isRestaurantAdmin) {
            this.$router.replace({ name: routeType.ORDER_ACTIVE })
        }
        if (this.isSuperAdmin) {
            this.$router.replace({ name: routeType.ORDER_LIST })
        }
    },
}
</script>

<template>
    <div class="text-center">Home sweet home</div>
</template>
